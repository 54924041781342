//breakpoint 0
@include media-breakpoint-up(xs) {

    .pillarpage-nav {

        background: #fff;
        z-index: 10;
        position: relative;
        display: none;
        counter-reset: item;
        padding: 29px 15px;
        margin: 0 -15px;
        max-height: calc(100vh - 76px - 40px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        &.active {
            display: block;
        }

        .item {
            font-size: 15px;
            line-height: 1.86;
            padding: 6px 0;
            padding-left: 30px;
            font-weight: 600;
            color: rgba(0,0,0, .5);
            text-align: left;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                counter-increment: item;
                content: "0" counter(item);
                width: 30px;
                display: inline-block;
                color: rgba(54,109, 102, .6);
            }

            &.active,
            &:active {
                color: #000;

                &:before {
                    color: rgba(54,109, 102, 1);
                }
            }
        }

        .item-inner {
            padding: 6px 0;
            padding-left: 50px;
            font-size: 15px;
            line-height: 1.86;
            font-weight: 600;
            color: rgba(0,0,0, .5);
            text-align: left;

            &:active {
                color: #000;
            }
        }
    }

    .articles-nav-col {
        position: relative;
        padding-bottom: 30px;

        .sticky-column {
            padding-top: 33px;
            display: none;
        }

        .title {
            font-size: 21px;
            line-height: 1.33;
            font-weight: 600;
            margin-bottom: 24px;
            color: #000;
        }

        .rounded-nav {
            margin-bottom: 35px;
        }

        .details {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 50px;

            .num {
                color: #2E7571;
                font-size: 25px;
                font-weight: 700;
                margin-right: 25px;
            }

            .button {
                font-size: 15px;
                color: rgba(0,0,0, .5);
                line-height: 1.86;
                font-weight: 700;

                position: relative;
                border: 2px solid transparent;
                padding: 0 10px;
                border-radius: 4px;
                transition: 0.3s;
                
                &:hover,
                &:active {
                    border-color: #6FC0BC;
                    text-decoration: none;
                }
            }
        }
    }

    .pillarpage2 {

        padding-top: 0;

        .articles-menu-slider {
            display: none;
        }

        .title-center {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .underline-header-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            text-align: center;

            .underline-header {
                min-width: 50px;
            }
        }

        .title-page {
            position: relative;
            font-size: 18px;
            color: #6FC0BC;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            &:before {
                content: "\E908";
                font-family: 'icomoon';
                margin-right: 14px;
                font-size: 21px;
                color: #6FC0BC;
            }
        }
        h3 {
            margin-bottom: 42px;
        }

        h4 {
            font-size: 21px;
            line-height: 1.33;
            font-weight: 600;
            margin-bottom: 24px;
            color: #000;
        }

        p {
            font-size: 15px;
        }

        img {
            margin: 24px 0;
        }
    }

    .anchor {
        padding-top: 10px;
    }

    .articles-menu {

        list-style: none;
        padding-left: 0;
        margin-bottom: 30px;

        li {

            margin-bottom: 0;
            
            a {
                display: block;
                padding: 10px 0;
                color: inherit;
                font-size: 15px;
                font-weight: 600;
                line-height: 1.73;
                color: rgba(0,0,0, .5);

                &:hover,
                &:active,
                &.active {
                    text-decoration: none;
                    color: #6FC0BC;
                }
            }
        }
    }

    .articles-menu-slider {
        position: absolute;
        right: -15px;
        top: 0;
        content: "";
        height: 50px;
        width: 3px;
        background-color: #6FC0BC;
        transition: 0.3s;
    }

    .article-nav {
        padding: 30px 0;
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .articles-nav-col {
        padding-bottom: 200px;

        .sticky-column {
            padding-top: 33px;
            padding-right: 50px;
        }

        .title {
            margin-bottom: 56px;
        }
    }

    .pillarpage2 {
        .articles-menu-slider {
            display: block;
        }

        .title-page {
            margin-bottom: 44px;
        }

        .title-center {
            display: block;
        }

        .underline-header-wrapper {
            display: block;
            text-align: left;
        }

        .sticky-column {
            display: block;
        }
    }

    .pillarpage-nav {
        display: none !important;
    }

    .articles-nav-col {
        border-right: 1px solid rgba(0,0,0, .1);
    }

    .articles-main-nav {
        padding-left: 65px;
        padding-bottom: 87px;
        padding-top: 38px;
    }

    .article-nav {
        padding: 70px 0 0;
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}

@media (max-height: 800px) and (min-width: 992px) {

    .articles-nav-col {

        .title {
            font-size: 15px;
            margin-bottom: 30px;
        }

        .articles-menu li a {
            font-size: 14px;
            padding: 5px 0;
        }
    }

    .articles-nav-col .details {
        margin-bottom: 30px;
    }

    .articles-nav-col .sticky-column {
        padding-right: 30px;
    }
}