//breakpoint 0
@include media-breakpoint-up(xs) {

    .results-results {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 30px;
        line-height: 1.3;
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .results-header {
        font-size: 34px;
        margin-bottom: 43px;
        margin-top: 45px;
    }

    .results-results {
        font-size: 21px;
        margin-bottom: 55px
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}