//breakpoint 0
@include media-breakpoint-up(xs) {
    .info-container {
        background: rgba(70,195,207, .1);
        padding: 40px 20px 38px;
        margin-bottom: 53px;

        p {
            font-weight: 600;
            font-size: 15px;
            line-height: 1.46;
            color: #000000;
            padding: 0 8px;
        }

        .button {
            display: table;
            margin: 0 auto;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .info-container {
        margin-top: 73px;
        margin-bottom: 65px;
        background: rgba(70,195,207, .1);
        padding: 46px 108px 59px 57px;
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
        }

        .button {
            flex-shrink: 0;
            margin-left: 139px;
        }
    }

    
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}