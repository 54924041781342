//breakpoint 0
@include media-breakpoint-up(xs) {
    .single-video {
        margin-bottom: 67px;
        margin-top: 56px;
        margin-left: auto;
        margin-right: auto;
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .single-video {
        margin-bottom: 80px;
        margin-top:  48px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}