//breakpoint 0
@include media-breakpoint-up(xs) {

    .tax-icon{
        width:35px;
        margin-right:18px;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .tax-icon--2{
        width:auto;
        margin-right:0;
    }

    .partners-services-2 {
        .item {
            position: relative;

            .icon {
                position: absolute;
                right: 18px;
                top: 17px;
                display: block;
                width: 35px;
                text-align: center;
                font-size: 22px;
                color: #2C2E30;

                &.icon-feature-1 {
                    font-size: 18px;
                }
            }

            .list {
                display: table;
                margin: 24px auto 25px;

                li {
                    font-size: 15px;
                    color: #272727;
                    margin-bottom: 18px;
                    padding-left: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .university-card-img-box {
                height: 250px !important;
                margin: 0 !important;
                margin-top: 50px !important;

                transition: all 0.2s;
            }

            .university-year {
                position: absolute;
                top: 10px;
                right: 15px;
                background-color: #18202d;
                padding-left: 5px;
                padding-right: 5px;

                color: #fff;
                opacity: 1;
                transition: all 0.25s;
            }

            .university-post-title {
                text-align: center;
                color: #000;
                font-size: 32px;
                font-weight: 700;

                transition: all 0.1s;

                height: 45px;
            }
            .university-post-subtitle {
                text-align: center;
                color: #000;
                font-size: 14px;
                font-weight: 600;

                transition: all 0.1s;
            }
            .university-text-under-logo {
                text-align: center;
                color: #fff;
                margin-top: 30px;
                opacity: 0;
                transition: all 0.25s;

                span {
                    font-weight: 600;
                }

                ul {
                    width: fit-content;
                    margin: auto;
                    margin-top: 5px;

                    li {
                        width: fit-content;
                        font-size: 13px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .u-item {
            height: 500px !important;
            transition: all 0.3s;

            padding: 35px !important;
            &:hover {
                background-color: #18202d !important;
                .university-btn-more {
                    opacity: 1 !important;
                }

                .university-card-img-box {
                    height: 150px !important;
                    margin-top: -25px !important;
                }

                .university-text-under-logo {
                    opacity: 1 !important;
                }

                .university-post-title {
                    color: #fff !important;
                }
                .university-post-subtitle {
                    color: #fff !important;
                }
                .university-year {
                    background-color: #fff;
                    color: #18202d;
                }
            }
        }



        .ebook-item {
            transition: all 0.3s;

            padding: 35px !important;

            .ebook-card-img-box {
                height: 250px !important;
                margin: 0 !important;
                margin-top: 0 !important;
                padding: 0 !important;
            }

            .ebook-post-title {
                text-align: center;
                color: #000;
                font-size: 26px;
                font-weight: 700;
                line-height: 32px;
                margin-bottom: 15px;
            }

            &:hover {
                background-color: #b9e9e6;
            }
        }
    }

    .clients-head-content {
        margin-bottom: 15px;
        
        .content {
            margin-top: 22px;
        }

        p {
            font-size: 15px;
            line-height: 1.73;
        }
    }

    .robot-head-content {
        align-items: center;
    }

    .container-robot {
        padding-top: 35px;
    }

    .feature-list {
        display: flex;
        flex-wrap: wrap;

        .item {

            display: flex;
            align-items: center;
            margin-bottom: 13px;
            width: 100%;
            
            .icon {
                display: block;
                width: 35px;
                margin-right: 17px;
                text-align: center;
                font-size: 22px;
                color: #2C2E30;

                &.icon-feature-1 {
                    font-size: 18px;
                }
            }

            .txt {
                color: #2C2E30;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    .university-btn-more {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;

        color: #fff;
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .partners-services-2 {
        margin: 0 -15px !important;
        display: flex;
        flex-wrap: wrap;
        padding-top: 25px;

        .item {
            width: calc(50% - 30px);
            margin: 0 15px 30px;

            .icon {
                right: 28px;
                top: 23px;
            }

            .list {
                margin-left: 30px;
                margin-top: 34px;
                margin-bottom: 25px;

                li {
                    text-align: left;
                }
            }
        }
    }

    .clients-head-content {

        margin-bottom: 45px;

        .content {
            margin-top: 0;
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    .partners-services-2 {

        .item {
            width: calc(33.33% - 30px);

            .list {
                margin-left: 20px;
            }
        }
    }

    .clients-head-content {

        margin-bottom: 52px;

    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .partners-services-2 {

        .item {

            .list {
                margin-left: 60px;

                &.list-columns {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    width: auto;
                    margin-left: -23px;
                    margin-right: -23px;

                    li {
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    .feature-list {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 50%;
            margin-bottom: 21px;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}