.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
.slideInUp {
    animation-name: slideInUp;
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}
.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}


//breakpoint 0
@include media-breakpoint-up(xs) {
    .cookies{
        display: none;
        background: #fff;
        bottom: 0;
        font-size: 14px;
        left: 0;
        padding: 0 50px;
        position: fixed;
        right: 0;
        z-index: 9999;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        @include el('text'){
            display: inline-block;
            text-align: center;
            padding: 15px 15px 60px;
            color: #000;
            a{
                color:#000;
                transition: color .3s ease-in-out;
                text-decoration: none;
                &:hover{
                    color:#6fc0bc;
                }
            }
            p{
                margin:0;
            }
        }
    
        @include el('close'){
            display: flex;
            justify-content: center;
            align-items: center;
            background: none;
            border-radius: 0;
            border: none;
            text-transform: uppercase;
            cursor: pointer;
            padding: 10px;
            margin-top: 5px;
            margin-bottom: 20px;
            text-decoration: none;
    
            span{
                margin-top: 1px;
                background: none;
                padding: 0;
                color: #000;
                transition: color $time ease-in-out;
            }
    
            i{
                font-size: 16px;
                margin-right: 5px;
                &:before{
                    color: #000;
                    transition: color $time ease-in-out;
                }
            }

            &:hover,
            &:focus{
                outline: 0;
                i:before,span{
                    color:$red;
                }
            }
    
        }
    
    }
}

//md: 768px
@include media-breakpoint-up(md) {

    .cookies{
        flex-direction: row;
        @include el('text'){
            text-align: left;
            padding: 17px 0;
            padding-left: 101px;
            background-position: 0 100%;
        }
        @include el('close'){
            margin:0;
            margin-left: 39px;
            padding: 5px 10px;
        }
    }

}
