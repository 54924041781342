//breakpoint 0
@include media-breakpoint-up(xs) {
    .policy {

        padding-bottom: 32px;

        h2 {

            font-size: 15px;
            color: #54565A;
            font-weight: 500;
            margin: 44px 0;
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}