//breakpoint 0
@include media-breakpoint-up(xs) {
    .time {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #8D8D8D;

        &:before {
            content: "\e922";
            font-family: 'icomoon';
            font-size: 18px;
            margin-right: 11px;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}