//breakpoint 0
@include media-breakpoint-up(xs) {
    .video-box {
        position: relative;
        max-width: 588px;
        height: 232px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .video-box {

        height: 415px;
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}