//breakpoint 0
@include media-breakpoint-up(xs) {
    .single-video {
        margin-bottom: 67px;
        margin-top: 56px;
    }

    .blog-page {
        h1 {
            margin-bottom: 27px;
        }
        h3 {
            margin-bottom: 27px;
        }

    }

    .blog-nav {

        background: #fff;

        .nav-mobile {
            display: block;
            width: 100%;
            -webkit-appearance: none;
            padding: 9px 14px;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5;
            color: #171717;
            background: #FFFFFF url('../img/icons/arrow-down-2.svg') calc(100% - 12px) 50% no-repeat;
            border: 1px solid rgba(0, 0, 0, 0.2);
            margin-bottom: 17px;
        }

        .nav-desktop {
            display: none;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 6px 0;

            li {
                margin-bottom: 0;

                a {

                    display: block;
                    padding: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.5;
                    color: #171717;
                    border-radius: 6px;
                    border: 1px solid transparent;
                    transition: 0.3s;

                    &:hover {

                        text-decoration: none;
                        border-color: #ddd;

                    }
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

    .blog-nav {

        border-bottom: 1px solid #E5E5E5;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.08);

        .nav-mobile {
            display: none;
        }

        .nav-desktop {
            display: flex;
        }
    }

    .blog-header {

        display: flex;
        flex-wrap: wrap;

        .blog-nav {
            order: -1;
            width: 100%;
            margin-bottom: 22px;
        }

        .breadcrumbs {
            margin-bottom: 23px;
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

    .blog-page {
        h1 {
            margin-bottom: 34px;
        }
        h3 {
            margin-bottom: 34px;
        }

    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}