//breakpoint 0
@include media-breakpoint-up(xs) {
    .result-item {

        margin-bottom: 30px;

        @include el('img') {
            width: 100%;
            height: 220px;
            flex-shrink: 0;
            margin-right: 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @include el('content') {

            .name {
                font-size: 21px;
                line-height: 1.33;
                font-weight: 600;
                color: #000;
                text-align: center;
                display: block;
                margin-top: 18px;
                margin-bottom: 9px;
                transition: 0.3s;

                &:hover {
                    text-decoration: none;
                    color: #6FC0BC;
                }
            }

            .details {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 13px;

                .author {
                    color: #6FC0BC;
                    font-size: 15px;
                }
            }

            .desc {
                margin-bottom: 13px;
                font-size: 15px;
                line-height: 1.73;
                font-weight: 500;
                text-align: justify;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {
    .result-item {

        display: flex;

        @include el('img') {
            width: 170px;
            height: 170px;
        }

        @include el('content') {

            .name {
                text-align: left;
                margin-top: 0;
            }

            .center {
                margin-left: 0;
            }
        }
    }
}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}