//breakpoint 0
@include media-breakpoint-up(xs) {
    .what-we-offer {
        padding-top: 61px;
        background: rgba(70, 195, 207, 0.1);
        padding-bottom: 66px;

        .item {

            display: flex;
            align-items: flex-start;
            align-items: center;
            margin-bottom: 32px;

            .img-box {
                flex-shrink: 0;
                position: relative;
                margin-right: 18px;
                align-self: flex-start;

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    font-size: 34px;

                    &.icon-our-benefit-6,
                    &.icon-our-benefit-8 {
                        font-size: 48px;
                    }

                    &.icon-our-benefit-8 {
                        transform: translateX(-50%) translateY(-40%);
                        font-size: 52px;
                    }

                    &:before {
                        color: #18202D;
                    }
                }
            }

            .content-box {
                font-size: 15px;
                line-height: 1.86;
            }
        }

        .apply {

            text-align: center;
            padding-top: 46px;

            h4 {
                color: #2C2E3F;
                margin-bottom: 47px;
                font-weight: 600;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .what-we-offer {

        margin-top: 39px;

        .item {

            margin-bottom: 90px;
        
        }

        .apply {
            padding-top: 0;
            margin-top: -32px;
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}