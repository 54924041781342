//breakpoint 0
@include media-breakpoint-up(xs) {
    .article-nav {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .article-nav-side {

            padding: 15px;
            flex-grow: 1;
            flex-basis: 0;

            &:hover {
                text-decoration: none;

                .name {
                    color: #6fc0bc;
                }
            }

            @include mod('prev') {
                text-align: right;
                border-right: 1px solid #E5E5E5;

                .underline-header {
                    &:after {
                        left: auto;
                        right: 0;
                    }
                }

                .rounded-nav-item {
                    margin-left: auto;
                }
            }

            @include mod('next') {
                
            }

            .underline-header {
                margin-bottom: 8px;
            }

            .name {
                font-size: 21px;
                font-weight: 600;
                margin-bottom: 36px;
                color: #000;
                transition: 0.3s;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .article-nav {
        .article-nav-side {
            padding: 56px 46px 52px;
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}