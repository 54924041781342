.newsletterBox {
  position: fixed;
  left: -350px;
  top: 20%;
  z-index: 999;
  width: 350px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &.show{
    left: 0;
  }

  p{
    color: #888888;
    font-size: 9px;
  }

  &__thankYou {
    display: none;

    p{
      padding: 60px 0;
      color: white;
      font-size: 12px;
    }

    &.show {
      display: block;
    }
  }

  &__info {
    position: absolute;
    right: -78px;
    background: #141d33;
    padding: 10px 15px;
    color: white;
    top: 53px;
    transform: rotate(270deg);
    border: 0;
    cursor: pointer;
  }

  &__form {
    background-color: #141d33;
    padding: 25px;
  }

  .form {
    display: none;

    &.show {
      display: block;
    }

    &__control {
      margin-bottom: 10px;

      input {
        border: 0;
        padding: 10px 15px;
        width: 100%;
      }

      .error{
        color: indianred;
        font-size: 10px;
      }
    }

    &__button {
      margin-bottom: 0;
      width: 100%;
      margin-top: 20px;
    }
  }

  .checkbox-ctn{
    input[type="checkbox"] {
      display: block;
      padding: 0;
    }

    .state{
      margin-top: 5px;
    }

    .txt {
      font-size: 10px;
      color: #888;
    }
  }
}