//breakpoint 0
@include media-breakpoint-up(xs) {
    .pagination {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 29px;
        margin-bottom: 47px;

        @include el('left') {
            margin-right: auto;
            transform: rotate(180deg);
        }

        @include el('right') {
            margin-left: auto;
        }

        @include el('item') {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #d8d8d8;
            border-radius: 50%;
            transition: 0.3s;
            visibility: visible;

            i {
                font-size: 15px;

                &:before {

                    color: #000;
                    transition: 0.3s;

                }
            }

            &:hover {
                background: #000;
                border-color: #000;
                text-decoration: none;

                i {
    
                    &:before {
    
                        color: #fff;
    
                    }
                }
            }
        }

        .pages {
            display: flex;
            justify-content: center;
            padding-left: 0;
            list-style: none;
            margin: 0 auto;
            flex-wrap: wrap;
            max-width: calc(100% - 100px);

            li {
                margin: 0 0 10px 0;
            }

            .page {
                display: block;
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                border: 1px solid transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                transition: 0.3s;

                &.active,
                &:hover {
                    border-color: #E5E5E5;
                    color: #E5E5E5;
                    text-decoration: none;
                }
            }
        }

    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {
    .pagination {
        margin-top: 61px;
        margin-bottom: 61px;

        @include mod('desktop-d-none') {
            display: none;
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}