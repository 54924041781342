//breakpoint 0
@include media-breakpoint-up(xs) {
    .rounded-nav {
        display: flex;

        .rounded-nav-item--prev {
            margin-right: 20px;
        }
    }

    .rounded-nav-item {
        background: #fff;
        border: 1px solid #E9E9E9;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;

        &:hover,
        &:active,
        &:focus {
            outline: none;
        }

        .icon {
            font-size: 15px;

            &:before {
                color: #000;
                transition: 0.3s;
            }
        }

        @include mod('prev') {

            .icon {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:active {
            background: #6FC0BC;
            border-color: #6FC0BC;

            .icon {

                &:before {
                    color: #fff;
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}