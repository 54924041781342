//breakpoint 0
@include media-breakpoint-up(xs) {
    .knowledge-centre-header {
        font-size: 18px;
        color: #6fc0bc;
        display: flex;
        align-items: center;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 0;
    }
    .pillarpage {
        h3 {
            margin-bottom: 30px;
            font-size: 34px;
            line-height: 1.35;
            color: #2C2E30;
        }
    }

    .pillars {
        margin-bottom: 125px;
        max-width: 260px;
        margin: 0 auto;
    }

    .pillar-center {
        display:block;
        margin: 40px auto 50px;
        padding: 34px 15px 20px;
        text-align: center;
        background: #2A3D5B;
        box-shadow: 10px 15px 20px 5px rgba(0, 0, 0, 0.3);

        .name {
            color: #6FC0BC;
            display: inline-flex;
            align-items: center;
            font-weight: 600;
            margin-bottom: 24px;

            &:before {
                content: "\e908";
                font-family: 'icomoon';
                margin-right: 14px;
                font-size: 21px;
            }
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 1.29;
            color: #FFFFFF;
            letter-spacing: 0;
            margin-bottom: 31px;
        }

        .graphic {
            margin: 0 auto 60px;
        }

        .logo {
            width: 60px;
        }
    }

    .pillar-list {
        @include el('list') {
            list-style: none;
            padding-left: 0;
            counter-reset: list;
            position: relative;
            margin-bottom: 22px;

            &:after {
                content: "\e92d";
                font-family: icomoon;
                font-size: 87px;
                line-height: 1;
                position: absolute;
                bottom: 0;
                right: 40px;
                color: #6FC0BC;
                transform: translateY(calc(100% + 16px));
            }

            li {
                
                margin-bottom: 20px;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);

                a {
                    background: #fff;
                    display: block;
                    transition: 0.3s;
                    padding: 17px 19px;
                    position: relative;

                    &:before {
                        counter-increment: list;
                        content: "0" counter(list);
                        font-weight: 600;
                        font-size: 25px;
                        color: #2E7571;
                        line-height: 1;
                        margin-bottom: 11px;
                        display: block;
                    }

                    &.active,
                    &:hover {
                        background: #2A3D5B;
                        text-decoration: none;

                        .name {
                            color: #fff;
                        }

                        &:before {
                            color: #fff;
                        }
                    }
                }

                .name {
                    font-weight: 600;
                    font-size: 15px;
                    color: #000000;
                }
            }
        }
    }

    .ebook {
        position: relative;
        margin-bottom: 109px;
        padding-top: 100px;

        .note {
            margin-bottom: 44px;
            font-size: 15px;
        }

        input {
            font-size: 16px;
            line-height: 1;
            color: #7E8085;
            padding: 10px 26px 12px;
            width: 100%;
            border: none;
            margin-bottom: 51px;
        }

        .icon-mobile {
            position: absolute;

            &.icon-mobile-1 {
                top: 12px;
                left: 75px;
                font-size: 30px;
                opacity: .2;
            }

            &.icon-mobile-2 {
                bottom: 0;
                right: 15px;
                font-size: 58px;
                opacity: .4;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .pillars {

        max-width: none;

        .pillar-list {

            @include el('list') {
                column-count: 2;
                column-gap: 30px;
                margin-bottom: 0;
            }
        }
    }

    .ebook {
        fieldset {

            .fields {
                display: inline-flex;

                input {
                    width: 300px;
                    margin: 0;
                }

                .btn {
                    margin-bottom: 0;
                }

            }
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    .pillars {
        position: relative;
        margin-top: 28px;
        padding: 0 45px;
        margin-bottom: 83px;

        .pillar-center {
            width: 302px;
            margin: 0 auto;
            position: absolute;
            z-index: 9;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            padding-bottom: 40px;
            padding-left: 30px;
            padding-right: 30px;

            .logo {
                width: 80px;
            }
        }

        .pillar-list {

            @include el('list') {
                column-gap: 44%;

                &:after {
                    right: 31%;
                    transform: translateX(0) translateY(60%);
                }
            }
        }
    }

    .ebook {
        padding-top: 0;
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .ebook {
        padding-top: 0;
        margin-bottom: 0;

        .icon-mobile {
            display: none;
        }
    }

    .pillarpage {
        padding: 109px;

        .scene {

            top: auto;
            height: 30%;

            .icon {
                font-size: 30px;
                opacity: 1;
            }
    
            &> div {
    
                position: relative !important; 
    
                &:first-child {
    
                    .icon {
                        position: absolute;
                        top: 6vh;
                        left: 32.3%;
                        opacity: 0.2;
                        font-size: 30px;
                    }
                }
    
                &:nth-child(2) {
    
                    .icon {
                        position: absolute;
                        top: 10vh;
                        right: 24.3%;
                        opacity: 0.4;
                        font-size: 115px;
                    }
                }
    
                &:nth-child(3) {
    
                    .icon {
                        position: absolute;
                        top: 15vh;
                        left: 23.3%;
                        opacity: 0.4;
                        font-size: 58px;
                    }
                }
    
                &:nth-child(4) {
    
                    .icon {
                        position: absolute;
                        top: 26vh;
                        right: 20.3%;
                        opacity: 0.4;
                        font-size: 58px;
                    }
                }
    
                &:nth-child(5) {
    
                    .icon {
                        position: absolute;
                        top: 35vh;
                        left: 40%;
                        opacity: 0.2;
                        font-size: 13px;
                    }
                }
    
                &:nth-child(6) {
    
                    .icon {
                        position: absolute;
                        top: 19vh;
                        right: 21%;
                        opacity: 0.1;
                        font-size: 39px;
                    }
                }
            }
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}