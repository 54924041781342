//breakpoint 0
@include media-breakpoint-up(xs) {
    .blog-item {
        background: #FFFFFF;
        box-shadow: 0px 1.71032px 30px rgba(0, 0, 0, 0.1);
        margin-bottom: 34px;

        @include el('img') {
            height: 168px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @include el('content') {

            padding: 0 15px 23px;

            .tag {
                display: table;
                margin: 0 auto 7px;
                font-weight: 600;
                font-size: 14px;
                line-height: 1.5;
                text-align: center;
                color: #FFFFFF;
                text-transform: uppercase;
                padding: 2px 10px 3px;
                transform: translateY(-50%);

                a {

                    margin: 0 5px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1.5;
                    text-align: center;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    padding: 2px 10px 3px;
                    background: #6FC0BC;
                    transform: translateY(-50%);
                }

            }

            .name {
                font-weight: 600;
                font-size: 20.5238px;
                line-height: 1.4;
                margin-bottom: 18px;
                text-align: center;
                color: #2C2E30;
                transition: 0.3s;
                display: block;
                margin-bottom: 22px;

                &:hover {
                    color: #6fc0bc;
                    text-decoration: none;
                }
            }

            .details {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 22px;

                .date {
                    margin-left: auto;
                    font-size: 15px;
                    color: #666666;
                    margin-right: 32px;
                }

                .author {
                    font-size: 15px;
                    color: #6FC0BC;
                }
            }

            .desc {
                font-size: 15px;
                line-height: 1.6;
                color: #666666;
                margin-bottom: 22px;
            }

            .btn-more {
                margin: 0 auto;
            }

        }

        @include mod('small') {

            .blog-item__img {
                margin-bottom: 18px;
            }

            .blog-item__content {

                .details {

                    .author {

                        margin-left: auto;

                    }
                }
            }

        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .blog-item {

        @include el('img') {
            height: 218px;
        }

        @include mod('small') {

            .blog-item__img {

                height: 167px;

            }

        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {
    .blog-item {

        @include el('img') {
            height: 311px;
        }

        @include el('content') {

            padding: 0 50px 23px;

            .name {
                font-size: 25px;
                text-align: left;
                margin-bottom: 34px;
            }

            .tag {
                margin-top: 30px;
                margin-bottom: 16px;
                transform: none;
                a{
                    color:#fff;
                    text-decoration: none;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}