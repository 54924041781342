//breakpoint 0
@include media-breakpoint-up(xs) {

    .case-study-comapny {
        margin-top: 8px;
        margin-bottom: 33px;

        .img-box {
            max-width: 300px;
            margin-bottom: 55px;

            img {
                width: 100%;
            }
        }

        .university-img-box {
            margin: 0 !important;
            max-height: 25vh !important;
        }

        .note {
            font-size: 15px;
            color: #999999;
            margin-bottom: 6px;
        }
        
        .name {
            font-size: 34px;
            color: #2C2E30;
            font-weight: 600;
        }
    }

    .case-study {
        h3 {
            margin: 30px 0;
        }
    }

    .case-study iframe {
        width: 100%;
        height: 450px;
        outline:0;
        border:0;
    }

    .cite-case-study {
        margin-bottom: 49px;
        display: block;
    }

    .person-data {
        margin-bottom: 50px;

        .img-box {
            width: 53px;
            height: 53px;
            margin-bottom: 17px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .name {
            font-size: 16px;
            font-weight: 700;
            font-family: 'aileronregular';
            margin-bottom: 12px;
            text-transform: uppercase;
            color: #2C2E3F;
        }

        .note {
            font-size: 14px;
            font-family: 'aileronregular';
            line-height: 1.3;
        }
    }

    .case-study-content {

        p {
            text-align: justify;
        }

        img {
            display: block;
            margin: 36px auto;
        }
    }

    .case-study-achieves {

        .col-12 {
            margin-bottom: 23px;
        }

        .underline-header {
            font-size: 34px;
            color: #6FC0BC;
            padding-bottom: 10px;
            margin-bottom: 18px;
        }
    }
}   

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {

    .case-col-offset {
        margin-top: 44px;
    }

    .case-study {
        h3 {
            margin: 48px 0;
        }
    }

    .case-study-comapny {
        padding-bottom: 50px;

        .img-box {
            height: 100vh;
            max-height: 12vh;

            img {
                max-height: 100%;
                width: auto;
            }
        }
    }
    
}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}