//breakpoint 0
@include media-breakpoint-up(xs) {
    .contact-page {
        
        .logo {
            margin-bottom: 55px;
        }

        .data {
            margin-bottom: 10px;

            .pin,
            .phone {
                margin-bottom: 30px;
            }

            .pin,
            .phone,
            .mail {
                display: flex;
                align-items: flex-start;
                transition: 0.3s;

                .icon-box {

                    display: flex;
                    justify-content: center;
                    flex-shrink: 0;
                    margin-right: 13px;
                    width: 23px;
                    

                    .icon {
                        font-size: 19px;
                        margin-top: 6px;

                        &:before {
                            color: #132138
                        }
                    }
                }

                .txt {
                    font-size: 16px;
                    line-height: 1.49;
                    color: #2C2E30;
                    transition: 0.3s;
                }
            }

            .phone {

                .icon-box {

                    .icon {
                        margin-top: 0;
                    }
                }
            }

            .mail {

                margin-bottom: 20px;

                .icon-box {

                    .icon {
                        font-size: 14px;
                    }
                }

            }

            .mail,
            .phone {
                &:hover {
                    text-decoration: none;

                    .txt {
                        color: #6fc0bc;
                    }
                }
            }
        }

        .map {
            margin-bottom: 62px;
            height: 256px;
            margin-left: -15px;
            margin-right: -15px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .contact-page {

        display: flex;
        flex-wrap: wrap;
        margin-bottom: 77px;

        .logo {
            margin-right: 39px;

        }

        .data {
            margin-right: 76px;

            .mail {
                margin-bottom: 0;
            }
        }

        .map {
            width: 100%;
        }
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {

}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .contact-page {

        flex-wrap: nowrap;
        align-items: center;

        .logo,
        .data,
        .map {
            margin-bottom: 0;
        }

        .logo {
            img {
                height: 256px;
            }
        }

        .data {
            .mail {
                .icon-box {
                    margin-bottom: 0;
                }
            }
        }

    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}