//breakpoint 0
@include media-breakpoint-up(xs) {
    .blog-item-page {

        h2 {
            text-align: center;

            &:after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .article {

            h3, .blog-single-item-title {
                text-align: center;
                line-height: 1.91;
                font-size: 24px;
                margin-bottom: 25px;
            };

            .time {
                text-align: center;
                margin: 0 auto 27px;
            }

            @include el('image') {
                height: 214px;
                margin: 0 -15px 27px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            p {
                font-size: 16px;
                line-height: 2;
                &.btn-more-wrap {
                    a {
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 1.2;
                        text-align: center;
                        color: #000000;
                        transition: 0.3s;
                        padding-bottom: 9px;
                
                        &:after {
                            content: "\e901";
                            font-family: 'icomoon';
                            font-size: 8px;
                            margin-left: 8px;
                        }
                
                        &:before {
                            display: block;
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 3px;
                            background: #6FC0BC;
                        }
                
                        &:hover {
                            color: #6fc0bc;
                            text-decoration: none;
                        }
                    }
                }
            }

            ul {

                margin-bottom: 24px;
                padding-left: 25px;

                li {
                    margin-bottom: 14px;
                    font-weight: 600;
                }
            }

            .article-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid #E5E5E5;
                padding: 21px 0 29px;

                .author-box {
                    display: flex;
                    align-items: center;

                    .img-box {
                        flex-shrink: 0;
                        width: 42px;
                        height: 42px;
                        margin-right: 10px;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .name {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .share-box {

                    .txt {
                        display: none;
                    }

                    .items {
                        display: flex;

                        a {
                            flex-shrink: 0;
                            width: 32px;
                            display: block;
                            margin-left: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid transparent;
                            transition: 0.3s;
                            padding: 5px;
                            border-radius: 4px;

                            &:hover {
                                border-color: #9B9B9B;
                                text-decoration: none;
                            }

                            i {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }

        .articles-nav {
            
            border-top: 1px solid #E5E5E5;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 46px;

            .container {

                display: flex;
                padding: 0;

            }

            @include el ('item') {
                padding: 54px 25px 60px;
                font-size: 21px;
                font-weight: 600;
                flex-grow: 1;
                flex-basis: 0;
                display: none;
                position: relative;
                color: #000;
                transition: 0.3s;

                &:before {
                    content: '\e923';
                    font-family: 'icomoon';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover {

                    color: #6fc0bc;
                    text-decoration: none;
                }

                @include mod('prev') {
                    border-right: 1px solid #E5E5E5;

                    &:before {
                        left: 10px;
                        transform: rotate(180deg) translateY(50%);
                    }
                }

                @include mod('next') {

                    &:before {
                        right: 10px;
                    }
                }

                @include mod('mobile') {
                    display: block;
                    text-align: center;
                }
            }
        }
        .gated {
            display: none;
            margin-top: 30px;
            &.sent {
                .gated__ty-wrap {
                    max-height: 300px;
                }
                .form {
                    max-height: 0;
                    overflow: hidden;
                }
                .gated__cta {
                    display: none;
                }
                .gated__lead-wrap {
                    display: none;
                }

            }
            &__ty-wrap {
                max-height: 0;
                overflow: hidden;
                transition: 0.25s all ease-in-out;
            }
            .form {
                max-height: 1000px;
                transition: 0.25s all ease-in-out;
            }
            .agrees {
                .txt {
                    font-size: 11px!important;
                    line-height: 1.7em;
                }
            }
        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    
}

//lg: 992px
@include media-breakpoint-up(lg) {

    .blog-item-page {

        .container-article {
            max-width: 600px;
            margin: 0 auto;
        }

    }

    .articles-nav {

        .container {
            max-width: 600px;
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {
    .blog-item-page {

        .container-article {
            max-width: 800px;
        }

        .breadcrumbs {
            margin-bottom: 30px;
        }

        .article {

            h3, .blog-single-item-title {
                font-size: 34px;
            }
    
            @include el('image') {

                height: 475px;
                margin-bottom: 45px;
            }

            .article-footer {

                padding: 31px 0 58px;

                .share-box {

                    display: flex;

                    .txt {
                        display: block;
                        font-size: 15px;
                        color: #999999;
                    }
                }

            }
        }

        .articles-nav {

            .container {
                max-width: 800px;
            }

            @include el('item') {
                
                display: block;
                padding: 100px 110px 100px 0;
                
                @include mod('mobile') {

                    display: none;;

                }

                @include mod('prev') {

                    &:before {

                        left: -108px;
                    }
                }

                @include mod('next') {

                    padding-left: 110px;
                    padding-right: 0;

                    &:before {

                        right: -108px;
                    }
                }
            }
        }
    }
}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}