//breakpoint 0
@include media-breakpoint-up(xs) {
    .breadcrumbs {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        padding-top: 4px;
        margin-bottom: 15px;

        li {
            font-size: 14px;
            margin-bottom: 0;

            a {
                color: inherit;
            }

            &:after {
                content: ">";
                margin: 0 4px;
            }

            &:last-child {

                &:after {
                    content: ""
                }

            }

        }
    }
}

//sm: 480px
@include media-breakpoint-up(sm) {

}

//md: 768px
@include media-breakpoint-up(md) {
    .breadcrumbs {
        margin-bottom: 75px;
    }
}

//lg: 992px
@include media-breakpoint-up(lg) {

    .breadcrumbs {
        @include mod('small-padding') {
            margin-bottom: 44px;
        }
    }
}

//xl: 1200px
@include media-breakpoint-up(xl) {

}

//sl: 1440px
@include media-breakpoint-up(sl) {
    
}